export const datenschutz = `
# Vishing

## Grundlagen Vishing
- Definition von Vishing
- Schäden von Vishing
- Wie kann die Infizierung durch Vishing erfolgen

## Maßnahmen gegen Vishing-Versuche
- Verhalten nach Vishing Erkennung
- Vishing Versuch erkennen
- Vishing-Prävention

## Arten und Beispiele von Vishing Angriffen
- Wardailing
- VoIP-Vishing
- Call-ID-Spoofing
- Voice Cloning
- Beispiel für einen Vishing-Versuch für einen IT-Support Vishing-Angriff 
- Beispiel für einen Vishing Angriff bei einem vorherigen Phishing-Angriff 

`;