export const phishing = `
# Phishing

## Grundlagen Phishing

- Definition Phishing URL
- Definition von Phishing
- Unterschied Phishing und Spam
- Schäden von Phishing
- Wie kann die Infizierung durch Phishing erfolgen

## Maßnahmen gegen Phishing-Versuche

- Verhalten nach Infizierung
- Infizierung erkennen
- Phishing-Präventation
- Verdächtige E-Mails identifizieren
- Erkennen von unsicheren Websiten

## Beispiele von Phishing-Angriffen

- Beispiel für einen Phishing-Versuch


`;