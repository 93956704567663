export const socialEngineering = `
# Grundlagen Social Engineering

## Einführung in Social Engineering

- Psychologische Aspekte des Social Engineerings
- Definition von Social Engineering

## Typen von Social Engineering-Angriffen

- Pretexting
- Baiting
- Phishing
- Tailgating
- Reverse Social Engineering
- Vishing

## Erkennen und Schutzmaßnahmen

- Allgemeine Schutzmaßnahmen
- Verdächtige E-Mails identifizieren
- Umgang mit verdächtigen Anrufen
 
`;