import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal, Collapse } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { userContext } from "../contexts/UserContext";
import {PrivacyPolicyText} from "../components/PrivacyPolicyText";
import { Radar, Bar } from "react-chartjs-2";
import { FaAngleDown, FaQuestionCircle } from 'react-icons/fa';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {InfoCircle} from "react-bootstrap-icons";
import axios from "axios";
import {AppSettings} from "../config";


type PlayerType = {
	id: number;
	title: string;
	slug: string;
	description: string;
	percentage: number;
	isMax: boolean;
};

type PlayerTypePostData = {
	user_id: number | undefined;
	philanthropist?: number;
	socialiser?: number;
	free_spirit?: number;
	achiever?: number;
	player?: number;
	disruptor?: number;
};
// Define the type for the array of player types
type PlayerTypes = PlayerType[];


export const PersonalizationPage: React.FC = () => {
	const context = useContext(userContext);
	const history = useHistory();
	const [gameElementQuestions, setGameElementQuestions] = useState<any[]>([]);
	const [hexadQuestions, setHexadQuestions] = useState<any[]>([]);
	const [formStep, setFormStep] = useState(0);
	const [chunkStep, setChunkStep] = useState(0);
	const [inChunkAnswered, setInChunkAnswered] = useState(0);
	const [playerTypeInfo, setPlayerTypeInfo] = useState<any[]>([]);
	const [mainPlayerTypesTitle, setMainPlayerTypesTitle] = useState([]);
	const [showError, setShowError] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
	const [playerTypesLabel, setPlayerTypesLabel] = useState<any[]>([]);
	const [playerTypesPercentage, setPlayerTypesPercentage] = useState<any[]>([]);
	const [maxPercentage, setMaxPercentage] = useState(0);
	const [showProgressbarSuccess, setShowProgressbarSuccess] = useState(false);

	// const playerTypesLabel = ['Disruptor', 'Free Spirit', 'Achiever', 'Player', 'Socialiser', 'Philanthropist'];
	// Define the type for a single player type


    const sliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

	function getPercentageById(playerTypes: PlayerTypes, id: number): number | undefined {
		const playerType = playerTypes.find(pt => pt.id === id);
		return playerType ? playerType.percentage * 100 : undefined;
	}

	async function createPlayertype(playerTypes: PlayerTypes){
		try {
			if (true) {

				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: context.actions.authHeader(),
					},
				};

				const PlayerTypePostData = {
					user_id: context.user?.user_id,
					philanthropist: getPercentageById(playerTypes, 1),
					socialiser: getPercentageById(playerTypes, 2),
					free_spirit: getPercentageById(playerTypes, 3),
					achiever: getPercentageById(playerTypes, 4),
					player: getPercentageById(playerTypes, 5),
					disruptor: getPercentageById(playerTypes, 6),
				};


				await axios.post(AppSettings.CREATE_PLAYER_TYPE, PlayerTypePostData, config)
					.then((response) => {
						if (response.status === 200) {
							console.log("response: ", response);

						}
					})
					.catch((error) => {
						console.error("Error creating player type:", error);
						console.log(error.message);
						console.log(error.request);
						console.log(error.config);
					});
			}
		} catch (error) {
			console.error("player type couldn't be updated", error);
		}
	}

	// get personalization data from backend
	const getPersonalization = () => {
		setLoading(true);
		context.actions
			.getPersonalization()
			.then((response: any) => {
				if (response.status === 200) {
					let data = response.data;
					if(data.status === 'success'){						
						history.push("/");
					} else if (data.status === 'initial') {
						let { game_element, hexad } = data.data;

						game_element = game_element.map((element: any) => {
							return {
								...element,
								score: 0,
								init: true,
								error: false,
							}
						});

						hexad = hexad.map((element: any) => {
							return {
								...element,
								score: 0,
								init: true,
								error: false,
							}
						});

						setGameElementQuestions(game_element);
						setHexadQuestions(hexad);
					}
				}
			})
			.catch((error: any) => {
				console.error(error);
			}).finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getPersonalization();
	}, []);

	const setPersonalization = () => {
		const hexadPayload = hexadQuestions.map((element: any) => {
			return {
				id: Number(element.id),
				score: Number(element.score),
			}
		});

		const gameElementPayload = gameElementQuestions.map((element: any) => {
			return {
				id: Number(element.id),
				score: Number(element.score),
			}
		});

		setLoading(true);
		context.actions
			.setPersonalization({
				hexad: hexadPayload,
				game_element: gameElementPayload,
			})
			.then((response: any) => {
				if (response.status === 200) {
					let data = response.data;
					if(data.status === 'success'){
						const playerTypes = data.data;

						console.log("playerTypes: ", playerTypes);
						createPlayertype(playerTypes);

						const playerMainTypesTitle = playerTypes.filter((playerType: any) => playerType.isMax).map((element: any) => {
							return element.title;
						});
						setMainPlayerTypesTitle(playerMainTypesTitle);

						const playerTypeInfo = playerTypes.map(({title, description, percentage}: any) => {
							return {
								title,
								description,
								percentage,
								open: false,
							}
						});
						setPlayerTypeInfo(playerTypeInfo);

						const playerTypesTitles = playerTypes.map((element: any) => element.title);
						setPlayerTypesLabel(playerTypesTitles);

						setPlayerTypesPercentage(playerTypes.map((element: any) => {
							const percentage = Math.round(element.percentage * 100);

							if (percentage > maxPercentage) {
								setMaxPercentage(percentage);
							}

							return percentage;
						}));

						window.scrollTo(0, 0);
						setFormStep(4);
					}
				}
			})
			.catch((error: any) => {
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleGameElementChange = (event: any, index: number) => {
		let newGameElementQuestions = [...gameElementQuestions];
        
        if (newGameElementQuestions[index].init) {
            setInChunkAnswered(inChunkAnswered + 1);
        }

		newGameElementQuestions[index].score = event.target.value;
		newGameElementQuestions[index].init = false;
		newGameElementQuestions[index].error = false;
		setGameElementQuestions(newGameElementQuestions);
	};

	const handleHexadChange = (event: any, index: number) => {
		let newHexadQuestions = [...hexadQuestions];

        if (newHexadQuestions[index].init) {
            setInChunkAnswered(inChunkAnswered + 1);
        }

		newHexadQuestions[index].score = event.target.value;
		newHexadQuestions[index].init = false;
		newHexadQuestions[index].error = false;
		setHexadQuestions(newHexadQuestions);
	};


	const setOpen = (title: string, open: boolean) => {
		const newPlayerTypeInfo = [...playerTypeInfo];

		const index = newPlayerTypeInfo.findIndex((element: any) => element.title === title);
		newPlayerTypeInfo[index].open = open;

		setPlayerTypeInfo(newPlayerTypeInfo);
	}

	function CollapsibleItem(title: string, description: string, percentage: number, open: boolean) {
		return (
			<div className="card mb-3 justify-content-between">
				<div className="card-header d-flex justify-content-between align-items-center w-100" style={{cursor: 'pointer'}} onClick={() => setOpen(title, !open)}>
					<h5 className="card-title mb-0">{title}: {Math.round(percentage * 100)}%</h5>
					<FaAngleDown className={`faq-icon ${open ? 'open' : ''}`} size={20} />
				</div>
				<Collapse in={open} role="button">
					<div className="card-body" id={`${title}-collapse-text`}>
						<p>{description}</p>
					</div>
				</Collapse>
		  </div>
		);
	  }

	const renderTooltip = (props: any, description: string) => (
		<Tooltip id="button-tooltip" {...props}>
			{description}
		</Tooltip>
	);

    const progressBar = () => {
        let width = 0;
        if (formStep === 1) {
            width = (chunkStep + 1) * 25;
        } else if (formStep === 3) {
            width = (chunkStep + 1) * 11.11;
        }

        if (width === 0) {
            return null;
        }

        // if progress add additional class to make it green
        let progressbarClasses = "progress-bar progress-bar-new";
        if (showProgressbarSuccess) {
            progressbarClasses = progressbarClasses.concat(" progress-bar-new--success");
            width = 100;
        }

        return (
            <div className="progress" style={{height: '1rem'}}>
                <div className={progressbarClasses} role="progressbar" style={{width: `${width}%`}} aria-valuenow={(chunkStep + 1) * 25} aria-valuemin={0} aria-valuemax={100}></div>
            </div>
        );
    }


	return (
        <div>
            <div
                className="d-flex align-items-center customHeight personalization-container mx-auto"
            >
                {isLoading ? (
                    <div className="container align-items-center d-flex justify-content-center">
                        <span
                                className="spinner-border spinner-border-sm ml-5"
                                role="status"
                                aria-hidden="true"
                            />
                    </div>) : (
                    <div className="container align-items-center box-blur" style={{marginTop: '3rem', marginBottom: '3rem', borderRadius: '20px', paddingTop: '20px', paddingBottom: '20px', color: '#000'}}>
                        <Form style={{ padding: "20px" }}>
                            {progressBar()}
                            { formStep === 0 ? (
                            <div>
                                <h3 className="text-center" style={{ paddingBottom: "1.5rem" }}>
                                    Bestimme deinen Spielertypen
                                </h3>
                                    <p className="text-center">
                                        Anhand der nachfolgenden Fragen bestimmen wir deinen Spielertypen. Auf Basis dieser Informationen können wir dir eine personalisierte Lernerfahrung auf unserer Plattform bieten.
                                    </p>
                                    <p className="text-center" style={{ paddingBottom: "1rem" }}>
                                        -3 bedeutet, dass du überhaupt nicht zustimmst und +3 bedeutet, dass du voll und ganz zustimmst.
                                    </p>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        style={{ width: "100%"}}
                                        className="mb-1 button-new"
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setFormStep(1);
                                        }}
                                    >
                                        Start
                                    </Button>
                                </div>
                            </div>) : null }
                            { formStep === 1 ? (
                            <div>
                                <Form.Group style={{marginTop: '1rem'}}>
                                    {sliceIntoChunks(hexadQuestions, 4).map((chunk, chunkIndex) => {
                                        if (chunkStep !== chunkIndex) {
                                            return null;
                                        }
                                        
                                        return (
                                            <div>
                                                {chunk.map((question, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Form.Label style={{ fontWeight: 'bold', marginBottom: '1rem', marginTop: '0.5rem', display: 'block' }} className="text-center">
                                                                {question.title}
                                                            </Form.Label>
                                                            <ButtonGroup aria-label={question.title} className="mt-1 justify-content-center d-flex btn-group-new">
                                                                {[-3, -2, -1, 0, 1, 2, 3].map(value => (
                                                                    <Button
                                                                        key={value}
                                                                        variant={value === question.score && !question.init ? 'primary' : 'light'}
                                                                        onClick={() => handleHexadChange({ target: { value } }, chunkIndex * 4 + index)}
                                                                    >
                                                                        {value} {question.init}
                                                                    </Button>
                                                                ))}
                                                            </ButtonGroup>
                                                            <div className="text-center" style={{ color: 'red', marginBottom: '1rem', visibility:  (question.error ? 'visible' : 'hidden')}}>
                                                                Bitte wähle einen Wert für diese Frage aus.
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <Button
                                                    variant="primary"
                                                    style={{ width: "100%"}}
                                                    className="mb-1 mt-3 button-new"
                                                    disabled={inChunkAnswered !== 4}
                                                    onClick={() => {
                                                        //window.scrollTo(0, 0);

                                                        let newHexadQuestions = [...hexadQuestions];
                                                        let error = false;
                                                        newHexadQuestions.forEach((question, index) => {
                                                            if(question.init && index >= chunkStep * 4 && index < (chunkStep + 1) * 4){
                                                                newHexadQuestions[index].error = true;
                                                                error = true;
                                                            }
                                                        });
                                                        setHexadQuestions(newHexadQuestions);

                                                        if(!error) {
                                                            setShowError(false);
                                                        } else {
                                                            setShowError(true);
                                                            return;
                                                        }
                                                        
                                                        setInChunkAnswered(0);

                                                        if (chunkIndex === sliceIntoChunks(hexadQuestions, 4).length - 1) {
                                                            setShowProgressbarSuccess(true);

                                                            setTimeout(() => {
                                                                setFormStep(2);
                                                                setChunkStep(0);
                                                                setShowProgressbarSuccess(false);
                                                            }, 1000);
                                                        } else {
                                                            setChunkStep(chunkIndex + 1);
                                                        }
                                                    }}
                                                >
                                                    Weiter
                                                </Button>
                                            </div>
                                        )
                                        
                                    })}
                                </Form.Group>
                            </div>) : null }
                            { formStep === 2 ? (
                            <div>
                                <h3 className="text-center" style={{ paddingBottom: "1.5rem" }}>
                                    Was sind deine präferierten Spielelemente?
                                </h3>
                                <p className="text-center">
                                    Wähle bitte für jede Frage aus, wie sehr dir das entsprechende Spielelement zusagt. Basierend darauf werden wir deine Lernerfahrung personalisieren.
                                </p>
                                <p className="text-center" style={{ paddingBottom: "1rem" }}>
                                    -3 bedeutet, dass du überhaupt nicht zustimmst und +3 bedeutet, dass du voll und ganz zustimmst.
                                </p>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        variant="primary"
                                        style={{ width: "100%"}}
                                        className="mb-1 button-new"
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setFormStep(3);
                                        }}
                                    >
                                        Start
                                    </Button>
                                </div>
                            </div>) : null }
                            { formStep === 3 ? (
                                <div>
                                    <Form.Group style={{marginTop: '1rem'}}>
                                        {sliceIntoChunks(gameElementQuestions, 4).map((chunk, chunkIndex) => {
                                            if (chunkStep !== chunkIndex) {
                                                return null;
                                            }
                                            
                                            return (
                                                <div>
                                                    {chunk.map((question, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Form.Label style={{ fontWeight: 'bold', marginBottom: '1rem', marginTop: '0.5rem', display: 'block' }} className="text-center">
                                                                    {question.title}
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        delay={{ show: 50, hide: 400 }}
                                                                        overlay={(props) => renderTooltip(props, question.description)}
                                                                        >
                                                                        <span className="ml-2"><InfoCircle /></span>
                                                                    </OverlayTrigger>
                                                                </Form.Label>
                                                                <ButtonGroup aria-label={question.title} className="mt-1 justify-content-center d-flex btn-group-new">
                                                                    {[-3, -2, -1, 0, 1, 2, 3].map(value => (
                                                                        <Button
                                                                            key={value}
                                                                            variant={value === question.score && !question.init ? 'primary' : 'light'}
                                                                            onClick={() => handleGameElementChange({ target: { value } }, chunkIndex * 4 + index)}
                                                                        >
                                                                            {value} {question.init}
                                                                        </Button>
                                                                    ))}
                                                                </ButtonGroup>
                                                                <div className="text-center" style={{ color: 'red', marginBottom: '1rem', visibility:  (question.error ? 'visible' : 'hidden')}}>
                                                                    Bitte wähle einen Wert für diese Frage aus.
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    <Button
                                                        variant="primary"
                                                        style={{ width: "100%"}}
                                                        className="mb-1 mt-3 button-new"
                                                        disabled={inChunkAnswered !== 4}
                                                        onClick={() => {
                                                            //window.scrollTo(0, 0);

                                                            let newGameElementQuestions = [...gameElementQuestions];
                                                            let error = false;
                                                            newGameElementQuestions.forEach((question, index) => {
                                                                if(question.init && index >= chunkStep * 4 && index < (chunkStep + 1) * 4){
                                                                    newGameElementQuestions[index].error = true;
                                                                    error = true;
                                                                }
                                                            });
                                                            setGameElementQuestions(newGameElementQuestions);

                                                            if(!error) {
                                                                setShowError(false);
                                                            } else {
                                                                setShowError(true);
                                                                return;
                                                            }
                                                        
                                                            setInChunkAnswered(0);

                                                            if (chunkIndex === sliceIntoChunks(gameElementQuestions, 4).length - 1) {
                                                                setShowProgressbarSuccess(true);

                                                                setTimeout(() => {
                                                                    setPersonalization();
                                                                }, 1000);
                                                            } else {
                                                                setChunkStep(chunkIndex + 1);
                                                            }
                                                        }}
                                                    >
                                                        Weiter
                                                    </Button>
                                                </div>
                                            )
                                            
                                        })}
                                    </Form.Group>
                                </div>) : null }
                            { formStep === 4 ? (
                                <div>
                                    <h3 className="text-center" style={{ paddingBottom: "2rem" }}>
                                        Dein Spielertyp lautet:{'\n'}<br />
                                        <span style={{fontWeight: 700}}>{mainPlayerTypesTitle.length === 1 ? mainPlayerTypesTitle[0] : mainPlayerTypesTitle.join(" & ")}</span>
                                    </h3>

                                    <Bar
                                        data={{
                                            labels: playerTypesLabel,
                                            datasets: [
                                            {
                                                label: '% Spielertyp',
                                                data: playerTypesPercentage,
                                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                borderColor: 'rgba(0, 0, 0, 1)',
                                                borderWidth: 1,
                                            },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                            y: {
                                                beginAtZero: true,
                                            },
                                            },
                                        }}
                                    />
                                    <h4 className="mb-4 text-center" style={{marginTop: '3rem'}}>Mehr über deine Spielertypen erfahren:</h4>
                                    {(
                                        playerTypeInfo.map(({title, description, percentage, open}, index) => {
                                            return (CollapsibleItem(title, description, percentage, open))
                                        })
                                    )}
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button
                                            variant="primary"
                                            className="button-new mt-4"
                                            style={{ width: "100%" }}
                                            onClick={() => {
                                                history.push("/");
                                            }}
                                        >
                                            Fertig
                                        </Button>
                                    </div>
                                </div>) : null }
                        </Form>
                    </div>
                )}
            </div>


            <footer className="text-muted bg-dark variant-dark footer">
                <div
                    className="container d-flex justify-content-between"
                    style={{ paddingBottom: "10px", paddingTop: "10px" }}
                >
                    <div>
                        <a href="https://fbi.h-da.de/">Hochschule Darmstadt Fachbereich Informatik</a>
                    </div>
                    <div className="privacyPolicyText" onClick={() => setPrivacyModalIsOpen(true)}>
                        Datenschutzerklärung
                    </div>
                    <div>
                        <a href="/#">Zum Seitenanfang</a>
                    </div>
                </div>
            </footer>

            <Modal
                size="lg"
                show={privacyModalIsOpen}
                onHide={() => setPrivacyModalIsOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Datenschutzerklärung
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PrivacyPolicyText />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setPrivacyModalIsOpen(false)}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
	);
};
